import { buildCreditCheckoutUrl } from '../../../core/constants/websiteConstants';
import {
  HttpServiceError,
  Subscription,
  StripeProviderPayload
} from '../../../core/types/serviceTypes';
import { purchaseSubscription } from '../../../core/services/subscriptionServices';
import trackerClient, {
  SubscriptionPurchaseEventType,
  SubscriptionViewName,
  SubscriptionInputType
} from './logging';
import { PaymentProvider } from '../../../core/types/subscriptionEnums';

export const purchaseWebSubscription = async (
  generalErrorCallback: () => void,
  failureCallback: (serviceError: HttpServiceError) => void,
  onRedirectAction: () => void,
  subscription: Subscription,
  purchaseFlowUuid: string,
  pathName: string,
  paymentProvider: string
): Promise<void> => {
  try {
    const response = await purchaseSubscription({
      targetKey: subscription.subscriptionTargetKey,
      stripeCancelUrlPathName: pathName,
      paymentProvider
    });

    if (paymentProvider === PaymentProvider.STRIPE && response.providerPayload) {
      const payload: StripeProviderPayload = JSON.parse(
        response.providerPayload
      ) as StripeProviderPayload;

      if (payload.CheckoutUrl) {
        trackerClient.sendExperienceSubscriptionEvent(
          purchaseFlowUuid,
          SubscriptionPurchaseEventType.VIEW_SHOWN,
          SubscriptionViewName.STRIPE_CHECKOUT,
          subscription,
          SubscriptionInputType.SUBSCRIBE
        );
        onRedirectAction();
        window.location.href = payload.CheckoutUrl;
      }
    } else if (paymentProvider === PaymentProvider.CREDITBALANCE && response.providerPayload) {
      trackerClient.sendExperienceSubscriptionEvent(
        purchaseFlowUuid,
        SubscriptionPurchaseEventType.VIEW_SHOWN,
        SubscriptionViewName.CREDIT_BALANCE_CHECKOUT,
        subscription,
        SubscriptionInputType.SUBSCRIBE
      );
      onRedirectAction();
      window.location.href = buildCreditCheckoutUrl(subscription.subscriptionTargetKey);
    } else {
      generalErrorCallback();
    }
  } catch (error) {
    const serviceError = error as HttpServiceError;
    if (serviceError.status === 500) {
      generalErrorCallback();
    }
    failureCallback(serviceError);
  }
};

export default { purchaseSubscription };

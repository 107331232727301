import React from 'react';
import { Loading } from 'react-style-guide';
import { useTranslation } from 'react-utilities';
import { FeatureSubscriptions } from '../../../core/constants/translationConstants';
import SubscriptionCard from './SubscriptionCard';
import { Subscription } from '../../../core/types/serviceTypes';

type TSubscriptionsListProps = {
  subscriptions: Subscription[] | undefined;
};

export const SubscriptionsList = ({ subscriptions }: TSubscriptionsListProps): JSX.Element => {
  const { translate } = useTranslation();

  if (!subscriptions) {
    return <Loading />;
  }

  if (subscriptions.length === 0) {
    return (
      <p className='section-content-off'>
        {translate(FeatureSubscriptions.MessageNoSubscriptionsAvailable)}
      </p>
    );
  }

  return (
    <div
      className={`${
        subscriptions !== undefined && subscriptions.length > 1 ? 'card-list' : ''
      } card-spacing-bottom`}>
      {subscriptions !== undefined &&
        subscriptions.map(sub => <SubscriptionCard key={sub.name} subscription={sub} />)}
    </div>
  );
};

export default SubscriptionsList;

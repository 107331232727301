import React from 'react';
import { render } from 'react-dom';
import { TranslationProvider } from 'react-utilities';
import { authenticatedUser } from 'header-scripts';
import { ready } from 'core-utilities';
import { BrowserRouter } from 'react-router-dom';
import SubscriptionsContainer from './containers/SubscriptionsContainer';
import '../../../css/gameSubscriptions/gameSubscriptions.scss';
import { getSubscriptionContainer } from '../../core/constants/browserConstants';
import { experienceSubscriptionTranslationConfig } from './translation.config';
import SystemFeedbackProvider from '../shared/providers/SystemFeedbackProvider';
import SingleButtonModalProvider from '../shared/providers/SingleButtonModalProvider';
import GameSubscriptionsProvider from './providers/GameSubscriptionsProvider';

ready(() => {
  // We only want to render subscriptions to authenticated users.
  if (getSubscriptionContainer() && authenticatedUser.isAuthenticated) {
    render(
      <SystemFeedbackProvider>
        <TranslationProvider config={experienceSubscriptionTranslationConfig}>
          <GameSubscriptionsProvider>
            <SingleButtonModalProvider>
              <BrowserRouter>
                <SubscriptionsContainer />
              </BrowserRouter>
            </SingleButtonModalProvider>
          </GameSubscriptionsProvider>
        </TranslationProvider>
      </SystemFeedbackProvider>,
      getSubscriptionContainer()
    );
  }
});

import { Subscription } from '../../../core/types/serviceTypes';

export enum GameSubscriptionActionTypes {
  UPDATE_PAGE = 'UPDATE_PAGE',
  LOAD_SUBSCRIPTIONS = 'LOAD_SUBSCRIPTIONS',
  OPEN_STRIPE_MODAL = 'OPEN_STRIPE_MODAL',
  CLOSE_STRIPE_MODAL = 'CLOSE_STRIPE_MODAL',
  SEND_STORE_PAGE_LOAD_EVENT = 'SEND_STORE_PAGE_LOAD_EVENT'
}

type ActionUpdatePage = {
  type: GameSubscriptionActionTypes.UPDATE_PAGE;
  newPage: number;
};
type ActionLoadSubscriptions = {
  type: GameSubscriptionActionTypes.LOAD_SUBSCRIPTIONS;
  subscriptions: Subscription[];
};

type ActionOpenStripeModal = {
  type: GameSubscriptionActionTypes.OPEN_STRIPE_MODAL;
  clientSecret: string;
  subscription: Subscription;
};

type ActionCloseStripeModal = {
  type: GameSubscriptionActionTypes.CLOSE_STRIPE_MODAL;
};

type ActionSendImpressionEvent = {
  type: GameSubscriptionActionTypes.SEND_STORE_PAGE_LOAD_EVENT;
};

type Action =
  | ActionUpdatePage
  | ActionLoadSubscriptions
  | ActionOpenStripeModal
  | ActionCloseStripeModal
  | ActionSendImpressionEvent;

export default Action;
